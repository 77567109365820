<template>
  <v-container
    id="typography"
    fluid
    tag="section"
  >
    <v-row
      align="center"
      justify="center"
      class="d-none d-sm-flex"
    >
      <!-- SUMMARY HIDDEN ON XS -->
      <v-col
        cols="12"
        sm="6"
        lg="3"
      >
        <base-material-stats-card
          color="warning"
          icon="mdi-send"
          title="Kiriman"
          :value="String(summary.total)"
          style="cursor: pointer;"
          @click.native="setStatus('')"
        />
      </v-col>
      <!--  -->
      <v-col
        cols="12"
        sm="6"
        lg="3"
      >
        <base-material-stats-card
          id="1"
          color="rgb(233, 30, 99)"
          icon="mdi-send-check"
          title="Terbitan"
          :value="String(summary.publish)"
          style="cursor: pointer;"
          @click.native="setStatus('publish')"
        />
      </v-col>
      <!--  -->
      <v-col
        cols="12"
        sm="6"
        lg="3"
      >
        <base-material-stats-card
          color="success"
          icon="mdi-send-lock"
          title="Ditunda"
          :value="String(summary.draft)"
          style="cursor: pointer;"
          @click.native="setStatus('draft')"
        />
      </v-col>
      <!--  -->
      <v-col
        cols="12"
        sm="6"
        lg="3"
      >
        <base-material-stats-card
          color="info"
          icon="mdi-trash-can"
          title="Dihapus"
          :value="String(summary.deleted)"
          style="cursor: pointer;"
          @click.native="setStatus('deleted')"
        />
      </v-col>
      <!--  -->
    </v-row>

    <v-row
      align="center"
      justify="center"
      class="d-flex d-sm-none"
    >
      <v-col
        cols="12"
        style="padding: 0"
      >
        <v-tabs
          v-model="tab"
          show-arrows
        >
          <v-tab
            v-for="item in items"
            :key="item.tab"
          >
            <v-badge
              color="green"
              :content="item.summary"
            >
              {{ item.title }}
            </v-badge>
          </v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab">
          <v-tab-item
            v-for="item in items"
            :key="item.tab"
          >
            <v-card flat>
              <v-card-text>
                <v-col
                  cols="12"
                  sm="6"
                  lg="3"
                >
                  <base-material-stats-card
                    color="info"
                    :icon="item.icon"
                    :title="item.title"
                    :value="String(item.summary)"
                    style="cursor: pointer;"
                    @click.native="setStatus(item.status)"
                  />
                </v-col>
                {{ item.content }}
              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>

    <v-row>
      <v-col
        cols="12"
        md="4"
        lg="4"
      >
        <v-text-field
          v-model="q"
          placeholder="Cari Judul News"
          append-icon="mdi-magnify"
          :label="$t('search')"
          color="secondary"
          hide-details
          @keyup="search"
        />
      </v-col>
      <!--  -->
      <v-col
        cols="12"
        md="2"
        lg="2"
        offset-md="6"
        offset-lg="6"
      >
        <v-btn
          to="/news/create"
          left
          width="100%"
          color="primary"
        >
          <span class="mr-1">
            <v-icon light>mdi-pencil-plus</v-icon>
          </span>
          Buat Baru
        </v-btn>
      </v-col>
    </v-row>
    <!--  -->
    <v-row>
      <v-col cols="12">
        <base-material-card
          icon="mdi-newspaper"
          :title="status !== '' ? 'News - ' + status : 'News - All'"
          class="px-5 py-3"
          style="text-transform: capitalize"
        >
          <div
            v-if="!isLoading"
            class="text-center mt-5 mb-5"
          >
            <v-progress-circular
              indeterminate
              color="primary"
            />
          </div>
          <div v-else>
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left primary--text">
                      #
                    </th>
                    <th class="text-left primary--text">
                      Judul
                    </th>
                    <th class="text-left primary--text">
                      Kategori
                    </th>
                    <th class="text-left primary--text">
                      Dibuat tanggal
                    </th>
                    <th class="text-left primary--text">
                      Status
                    </th>
                    <th class="text-center primary--text">
                      Aksi
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(data, index) in news.data"
                    :key="data.id"
                  >
                    <td>{{ index + news.meta.from }}</td>
                    <td>{{ data.title }}</td>
                    <td>
                      {{ data.category != null ? data.category.name : '-' }}
                    </td>
                    <td>{{ data.created_at | moment('D MMM YYYY') }}</td>
                    <td style="text-transform: capitalize">
                      {{ data.status }}
                    </td>
                    <td class="text-center">
                      <v-tooltip
                        bottom
                        color="info"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <!--  -->
                          <v-btn
                            icon
                            v-bind="attrs"
                            color="blue"
                            :to="'/news/' + data.id + '/show/'"
                            v-on="on"
                          >
                            <v-icon small>
                              mdi-pencil
                            </v-icon>
                          </v-btn>
                        </template>
                        <span>Edit</span>
                      </v-tooltip>

                      <v-tooltip
                        bottom
                        color="red"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <!--  -->
                          <v-btn
                            color="red lighten-2"
                            icon
                            v-bind="attrs"
                            v-on="on"
                            @click="openDialog(data.id, data.title)"
                          >
                            <v-icon small>
                              mdi-delete
                            </v-icon>
                          </v-btn>
                        </template>
                        <span>Delete</span>
                      </v-tooltip>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
            <div class="text-center">
              <v-pagination
                v-model="page"
                :length="news.meta.last_page"
                total-visible="5"
                @input="getResults"
              />
            </div>
          </div>
        </base-material-card>
      </v-col>
      <v-col cols="12">
        <template>
          <v-dialog
            v-model="dialog.open"
            width="500"
            :retain-focus="false"
          >
            <v-card>
              <v-card-title primary-title>
                Confirmation
              </v-card-title>

              <v-card-text>
                Anda yakin akan menghapus data News dengan judul "{{
                  dialog.title
                }}"?
              </v-card-text>

              <v-divider />

              <v-card-actions>
                <v-btn
                  color="primary"
                  @click="dialog.open = false"
                >
                  BATAL
                </v-btn>
                <v-spacer />
                <v-btn
                  color="error"
                  @click="deleteItem(dialog.id)"
                >
                  HAPUS
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </template>
      </v-col>
    </v-row>
    <!-- END SUMMARY HIDDEN ON XS -->
  </v-container>
</template>

<script>
  import axios from 'axios'
  axios.defaults.baseURL = 'https://nc.notiva.net'
  axios.defaults.headers.common.Authorization =
    'Bearer ' + localStorage.getItem('access_token')
  export default {
    name: 'IndexNews',
    data () {
      return {
        news: {
          meta: {},
          links: {
            first: null,
          },
        },
        summary: {
          total: 0,
          review: 0,
          draft: 0,
          publish: 0,
          deleted: 0,
        },
        isLoading: false,
        dialog: {
          open: false,
          id: 0,
          title: '',
        },
        page: parseInt(this.$route.query.page) || 1,
        q: this.$route.query.q || '',
        status: this.$route.query.status || '',
        offset: this.$route.query.offset || 10,
        tab: null,
        items: [
          {
            tab: 'One',
            title: 'Kiriman',
            icon: 'mdi-send',
            summary: '0',
            status: '',
          },
          {
            tab: 'Two',
            title: 'Terbitan',
            icon: 'mdi-send-check',
            summary: '0',
            status: 'publish',
          },
          {
            tab: 'Three',
            title: 'Ditunda',
            icon: 'mdi-send-lock',
            summary: '0',
            status: 'draft',
          },
          {
            tab: 'Four',
            title: 'Dihapus',
            icon: 'mdi-trash-can',
            summary: '0',
            status: 'deleted',
          },
        ],
      }
    },
    watch: {
      page (newVal) {
        this.$router.push({ query: { ...this.$route.query, page: newVal } })
      },
      '$route.query.page': function (val) {
        this.page = parseInt(val)
      },
      offset (newVal) {
        this.$router.push({ query: { ...this.$route.query, offset: newVal } })
      },
      '$route.query.offset': function (val) {
        this.offset = parseInt(val)
      },
      q (newVal) {
        this.$router.push({ query: { ...this.$route.query, q: newVal } })
      },
      '$route.query.q': function (val) {
        this.q = val
      },
      status (newVal) {
        this.$router.push({ query: { ...this.$route.query, status: newVal } })
      },
      '$route.query.status': function (val) {
        this.status = val
      },
    },
    mounted () {
      this.getSummary()
      this.getResults()
    },
    methods: {
      openDialog (id, title) {
        this.dialog.id = id
        this.dialog.title = title
        this.dialog.open = true
        console.log(this.dialog.id)
      },
      deleteItem (id) {
        this.dialog.open = false
        axios.delete('/v1/post/news/' + id).then(response => {
          if (response.data.meta.status) {
            this.$toast.error('Berhasil menghapus data', {
              type: 'error',
              position: 'top-right',
              duration: 3000,
              dismissible: true,
            })
          } else {
            console.log(response.data)
          }

          // Update
          this.getResults()
        })
      },
      setStatus (val) {
        this.status = val
        this.getResults()
      },
      setNews (data) {
        return (this.news = data)
      },
      setSummary (data) {
        this.summary = data
        this.items.forEach(item => {
          if (item.status === '') {
            item.summary = this.summary.total
          } else if (item.status === 'publish') {
            item.summary = this.summary.publish
          } else if (item.status === 'draft') {
            item.summary = this.summary.draft
          } else if (item.status === 'deleted') {
            item.summary = this.summary.deleted
          } else {
            item.summary = 0
          }
        })
      },
      getSummary () {
        axios.get('/v1/post/news/summary').then(response => {
          this.setSummary(response.data.data)
        })
      },
      search () {
        if (this.timer) {
          clearTimeout(this.timer)
          this.timer = null
        }
        this.timer = setTimeout(() => {
          this.getResults()
        }, 700)
      },
      // Our method to GET results from a API endpoint
      getResults (page = this.page) {
        this.isLoading = false
        let search = ''
        if (this.q !== '') {
          search = '?filter[title][like]=' + this.q
        }
        const params = {
          status: this.status,
          page: page,
          sort: '-id',
          entities: 'image,thumbnail,category',
          offset: this.offset,
        }
        axios
          .get('/v1/post/news' + search, {
            params: params,
          })
          .then(response => {
            this.setNews(response.data)
            this.isLoading = true
          })
      },
    },
  }
</script>

<style></style>
